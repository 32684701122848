import { Controller } from '@hotwired/stimulus'
import { downloadDesignStepFile } from '../helpers/LegacyJquery'

export default class extends Controller {
  static values = { assemblyGraphJson: String, buttonId: String }

  download() {
    const assemblyGraphJSON = JSON.parse(this.assemblyGraphJsonValue)
    downloadDesignStepFile(assemblyGraphJSON, this.buttonIdValue)
  }
}
